import React from "react"
import backgroundPng from "../../assets/img/advantages/bg.png"

export default function AdvantagesSection() {
  return (
    <section
      className="advantages"
      id="company"
      style={{
        background: `transparent url(${backgroundPng}) left -130px top 63px no-repeat`,
      }}
    >
      <div className="container">
        <div
          className="advantages-info wow bounceInRight"
          style={{ /* visibility: "hidden",*/ animationName: "none" }}
        >
          <h3 className="advantages-title section-title section-title--semi-bold">
            Почему стоит обратиться именно к нам?
          </h3>
          <p className="advantages-descr">
            Сегодня многие работают в этой рыночной нише, как различные
            компании, так частные предприниматели. Однако не каждая скупка
            катализаторов может предложить клиенту:
          </p>
          <ul className="advantages-list">
            <li className="advantages-list__item">
              Приемлемые цены, соотносящиеся с ценами на биржах LME на момент
              заключения соглашения
            </li>
            <li className="advantages-list__item">
              Точный химический и спектральный анализ содержимого,
              осуществляемый лишь с помощью современного высокотехнологичного
              оборудования
            </li>
            <li className="advantages-list__item">
              Продажа катализатора без участия клиента, из другого населенного
              пункта
            </li>
            <li className="advantages-list__item">
              Мы гарантируем проведение анализа и выплату в день получения
              отработанного материала
            </li>
            <li className="advantages-list__item">
              Чуткость и доброжелательность к каждому клиенту
            </li>
            <li className="advantages-list__item">Прозрачность сделки</li>
          </ul>
        </div>
      </div>
    </section>
  )
}
