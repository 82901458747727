import React from "react"

import Layout from "../../components/Layout"
import HeadSection from "../../components/HeadSection"
import Header from "../../components/Home/Header"
import CatalogMetal from "../../components/CatalogMetal"
import AdvantagesSection from "../../components/Home/AdvantagesSection"
import OfferSection from "../../components/Home/OfferSection"

import { metalCars } from "../../components/CatalogMetal/data"

const CatalogMetalPage = () => {
  return (
    <>
      <HeadSection
        title="Цены на металлические катализаторы"
        description="Каталог металлические катализаторов, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором катализатора."
      />
      <Layout>
        <div className="screen">
          <Header />
        </div>
        <CatalogMetal data={metalCars} />
        <AdvantagesSection />
        <OfferSection />
      </Layout>
    </>
  )
}

export default CatalogMetalPage
