import React from "react"
import { Link } from "gatsby"

function PriceCard({ link, name, year, img, price, date }) {
  return (
    <div className="price-card-col">
      <Link to={link}>
        <div className="price-card">
          <div className="price-card__row" style={{ marginBottom: "15px" }}>
            <span>{name} </span>
            <h5 className="price-card__header" style={{ display: "inline" }}>
              <span
                className="price-card__header-type"
                style={{ color: "#ff8a2c", display: "inline" }}
              >
                {year} г
              </span>
            </h5>
          </div>

          <div className="price-card__img">
            <img
              src={img}
              alt={`Фото катализатора с ${name}`}
              style={{ marginBottom: "30px" }}
            />
          </div>
          <div className="price-card__row mb-3"> Выплата составила</div>
          <h5 className="price-card__header" style={{ marginBottom: "15px" }}>
            <span className="price-card__header-material">{price} ₽</span>
          </h5>
          <div className="" style={{ fontSize: "13px", color: "#afafaf" }}>
            Дата публикации: {date}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PriceCard
